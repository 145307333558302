@import '../../styles/_variables.scss';

.offer {
  position: relative;
  padding: 80px 0;
  .offerWrapper {
    .mainTitleWrapper{
      display: flex;
      justify-content: center;
      position: relative;

      .mainTitle{
        border-bottom: 5px solid #F28686;
        padding: 0 0 15px 0;
        font-size: 38px;
        text-align: center;
        font-weight: 700;
      }
    }
    .offerGrid{
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      .offerItem{
        box-shadow: 8px 8px 24px -19px rgba(66, 68, 90, 1);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid $dark-pink;
        padding: 25px 30px;
        border-radius: 12px;
        transition: 0.3s;
        justify-content: space-between;
        &:hover{
          background-color: #f2f2f2;
        }
        .offerIcon{
          width: 100%;
        }
        .offerTitle{
          margin-bottom: 24px;
          font-size: 28px;
          text-align: center;
          color: $dark-pink;
        }
      }
    }
    .mainDesc{
      margin-top: 50px;
      h2{
        font-size: 32px;
        font-weight: 500;
      }
      ul{
        margin: 20px 0;
        li{
          margin: 10px 0;
        }
      }
      p{
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
      strong{
        font-size: 19px;
        margin-right: 2px;
      }
    }
  }
}

@media(max-width: 1000px){
  .offer{
    .offerWrapper{
      .offerGrid{
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media(max-width: 600px){
  .offer{
    .offerWrapper{
      .offerGrid{
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}