@import '../../../styles/_variables.scss';

.bannerWrapper{
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  .banner{
    background-color: rgba(0, 0, 0, 0.47);
    padding: 32px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .bannerTitle{
      font-size: 32px;
      color: #fff;
    }
    .offerBreadcrumbs{
      position: relative;
      top: 0;
      left: 0;
      transform: unset;
      margin-top: 6px;
      span{
        color: #fff;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}
