.breadcrumbs{
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  display: flex;
  align-items: center;
  .breadcrumbLinkWrapper{
    display: flex;
    align-items: center;
    .breadcrumbLink{

      span{

        font-weight: 400;

      }
    }
  }

}

@media(max-width: 700px){
  .breadcrumbs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .breadcrumbLink{
      display: flex;

    }
  }
}