@import '../../../styles/_variables.scss';

.aboutMeWrapper{
  overflow: hidden;
  padding: 100px 0;
  position: relative;
  background-color: $light-pink;
  .aboutMe{
    max-width: $max-page-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .aboutMeTitle{
      font-size: 38px;
      font-weight: 700;
      border-bottom: 5px solid $dark-pink;
      padding: 0 0 15px 0;
      transition: 1s;
    }
    .aboutMeInformation{
      display: flex;
      justify-content: space-between;
      margin: 50px 0 0 0;
      width: 100%;
      position: relative;
      .selfPhoto{
        position: relative;
        width: 40%;
        left: 0;
        transition: 1s;
      }
      .textSection{
        transition: 1s;
        right: 0;
        position: relative;
        border-left: 2px solid $dark-pink;
        padding: 20px 0 0 50px;
        width: 50%;
        .text{
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
        }
        .seeMore{
          color: #fff;
          margin: 25px 0 0 0;
          background-color: #F28686;
          padding: 10px 20px;
          border-radius: 16px;
          font-size: 30px;
          display: flex;
          align-items: center;
          transition: 0.5s;
          justify-content: center;
          &:hover{
            border-bottom: 3px solid white;
            border-radius: 0;
          }
        }
      }
    }
  }
}

@media(max-width: 900px){
  .aboutMeWrapper{
    .aboutMe{
      .aboutMeInformation{
        flex-direction: column;
        align-items: center;
        .textSection{
          width: 80%;
          text-align: center;
          border-left: none;
          padding: 30px 0 0 0;
          margin: 30px 0 0 0;
          border-top: 2px solid $dark-pink;
        }
      }
    }
  }
}

@media(max-width: 600px){
  .aboutMeWrapper{
    .aboutMe{
      .aboutMeInformation{
        .selfPhoto{
          width: 50%;
        }
      }

    }
  }
}

@keyframes opacityShow {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}