@import '../../../../styles/_variables.scss';
.bottomPanel{
  display: flex;
  align-items: stretch;
  height: 100%;
  .bottomPanelInput{
    width: 100%;
    padding: 8px 6px;
    border-radius: 12px;
    font-size: 16px;
    margin-right: 12px;
    border: 2px solid #c5c5c5;
    &:focus{

      outline: 0;
    }
  }
  .bottomPanelSend{
    display: inline-block;
    border-radius: 12px;
    padding: 0 10px;
    cursor: pointer;
    background-color: $light-pink;
  }
}

@media(max-width: 700px){
  .bottomPanel{
    position: absolute;
    bottom: 0;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;
  }
}