@import '../../styles/_variables.scss';

.noMatch{
  width: 100%;
  height: 100vh;
  background-color: $light-pink;
  display: flex;
  justify-content: center;
  align-items: center;
  .noMatchContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    .logoImage{
      width: 300px;
      margin-bottom: 20px;
    }
    .noMatchTitle{
      color: #fff;
      font-size: 40px;
    }
    .noMatchText{
      font-size: 20px;
      color: #fff;
      margin: 16px 0;
      max-width: 400px;
      text-align: center;
      line-height: 25px;
    }
    .homeBackButton{
      background-color: $dark-pink;
      padding: 10px 20px;
      border-radius: 20px;
      color: #fff;
      font-size: 18px;
      transition: 0.3s;
      &:hover{
        border-radius: 0;
      }
    }
  }
}