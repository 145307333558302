.bottomText{
  .bottomTextTitle{
    font-size: 20px;
    font-weight: 400;
  }
  .bottomTextList{
    margin: 10px 0;
    .bottomTextItem{
      font-size: 16px;
      font-weight: 300;
    }
  }
  .bottomTextParagraph{
    font-size: 16px;
    font-weight: 300;
  }
}