@import '../../../../styles/_variables.scss';

.content{
  .contentTitleWrapper{
    display: flex;
    .contentTitle{
      padding-bottom: 8px;
      border-bottom: 2px solid $dark-pink;
      font-size: 28px;
    }
  }
  .contentText{
    font-size: 16px;
    line-height: 20px;
    p{
      font-weight: 400;
    }
    li{
      margin-bottom: 6px;
    }
  }
}