@import '../../../styles/_variables.scss';

.offerWrapper{
  position: relative;
  padding: 80px 0;
  .offer{
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .offerTitle{
      text-align: center;
      border-bottom: 5px solid $dark-pink;
      padding: 0 0 15px 0;
      margin: 0 25px;
      font-size: 38px;
      font-weight: $thin-bold;
    }
    .offerText{
      margin: 25px 25px;
      line-height: 22px;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      max-width: 800px;
    }
  }
  .singleOfferWrapper{
    margin: 25px 0 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
}

@media(max-width: 1300px){
  .offerWrapper{
    .singleOfferWrapper{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media(max-width: 600px){
  .offerWrapper{
    .singleOfferWrapper{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}