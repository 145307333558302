@import '../../styles/_variables.scss';

.gallery{
  position: relative;
  padding: 80px 0;
  .galleryTitleWrapper{
    position: relative;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    .galleryTitle{
      border-bottom: 5px solid #F28686;
      padding: 0 0 15px 0;
      font-size: 38px;
      font-weight: 700;
    }
  }
}