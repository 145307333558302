@import '../../styles/_variables.scss';

.contactForm {
  width: 100%;
  border-right: 2px solid $dark-pink;
  padding-right: 50px;
  .inputWrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    .item{
      position: relative;
      grid-column: span 1;
      padding-bottom: 16px;
      .errorMessage{
        margin-top: 2px;
        position: absolute;
        color: rgba(255, 0, 0, 0.75);
        font-size: 14px;
      }
      &:nth-last-child(1){
        grid-column: span 2;
      }
      .input{
        border-radius: 16px;
        padding: 16px 0 16px 14px;
        width: 100%;
        font-size: 16px;
        border: 1px solid #8d8a8a;
        &::placeholder{
          font-size: 16px;
        }
        &:focus{
          outline: none;
          border: 1px solid $dark-pink;
        }
      }
      .label{
        top: -6px;
        background-color: #fff;
        font-size: 14px;
        left: 14px;
        position: absolute;
      }
    }
    .textarea{
      resize: none;
      width: 100% !important;
      height: 150px;
      font-size: 16px;
      border-radius: 16px;
      padding: 16px 0 16px 14px;
      border: 1px solid #8d8a8a;
      &:focus{
        outline: none;
      }
      &::placeholder{
        font-weight: 200;
        font-size: 16px;
      }
    }
  }
  .adminInfromation{
    font-size: 14px;
    opacity: 0.7;
  }
  .submitButton{
    background-color: #F28686;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 0;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 12px;
  }
  .information{
    margin-top: 12px;
    font-weight: 200;
    color: #8d8a8a;
  }
}

@media(max-width: 1000px){
  .contactForm{
    border-right: none;
    padding-right: 0;
    padding-bottom: 20px;
    border-bottom: 2px solid $dark-pink;
  }
}

@media(max-width: 600px){
  .contactForm{
    .inputWrapper{
      column-gap: 0;
      grid-template-columns: repeat(1, 1fr);
      .item{
        grid-column: 1;
      }
    }
  }
}