.questionDisplay{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .qaTitle{
    text-align: center;
    border-bottom: 5px solid #F28686;
    padding: 0 0 15px 0;
    margin: 0 25px 20px 25px;
    font-size: 38px;
    font-weight: 700;
  }
}