.hidden{
  opacity: 0;
  transition: 0.5s;
}

.visible{
  opacity: 1;
  transition: 0.5s;
}

.circle{
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  opacity: 0.4;
}

.verySmall{
  width: 25px;
  height: 25px;
}
.small{
  width: 50px;
  height: 50px;
}
.medium{
  width: 100px;
  height: 100px;
}
.big{
  width: 200px;
  height: 200px;
}
.veryBig{
  width: 300px;
  height: 300px;
}

