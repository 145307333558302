@import '../../../styles/_variables.scss';


.singleOffer{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 400px;
  position: relative;
  padding: 0 20px;
  transition: 0.5s;
  &:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: #000;
    transition: 0.5s;
  }
  &:after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: $dark-pink;
    transition: 0.5s;
  }
  &:hover{
    &:after{
      opacity: 0.4;
    }
  }
  .singleOfferTitle{
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 26px;
    text-transform: uppercase;
  }
}


