@import '../../../../styles/_variables.scss';

.exampleQuestions{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  .exampleQuestionsButton{
    background: transparent;
    border: 1px solid $light-pink;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 300;
    cursor: pointer;
  }
}