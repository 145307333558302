@import '../../../styles/_variables.scss';
.offerSubmenu {
  position: absolute;
  top: 29px;
  z-index: 9999;
  background-color: $dark-pink;
  padding: 20px;
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .offerSubmenuLoop{
    display: flex;
    flex-direction: column;
    .mainOffersLink{
      font-size: 16px;
      font-weight: 400;
    }
    .offerSubmenuLoopList{

      margin-top: 4px;
      .offerSubmenuLoopItem{
        margin-top: 4px;
        .offerSubmenuLoopLink{
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
}

.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}