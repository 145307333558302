@import '../../../styles/_variables.scss';

.statistics{
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  .statisticsTitleWrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    .statisticsTitle{
      font-size: 38px;
      font-weight: 700;
      border-bottom: 5px solid #F28686;
      padding: 0 0 15px 0;
    }
  }
  .statisticsLoop{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 28px;
    .statisticsLoopItem{
      padding: 50px 20px;
      border: 2px solid $dark-pink;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .statisticsLoopItemValue{
        font-size: 36px;
        color: $dark-pink;
        font-weight: 500;
      }
      .statisticsLoopItemText{
        font-size: 18px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}

@media(max-width: 1100px){
  .statistics{
    .statisticsLoop{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media(max-width: 600px){
  .statistics{
    .statisticsLoop{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}