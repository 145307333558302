@import '../../styles/_variables.scss';

.navigationWrapper{
  position: relative;
  background-color: $light-pink;
  padding: 50px 0 50px 0;

  .navigation{
    justify-content: flex-end;
    display: flex;
    max-width: $max-page-width;
    margin: 0 auto;
    position: relative;
    .logoLink{
      z-index: 2;
      left: 50px;
      bottom: 50%;
      transform: translateY(50%);
      position: absolute;
      width: 250px;
      .logoLinkImage{
        width: 100%;
      }
    }

    .listOfLinks {
      margin: 0 50px 0 0;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .item{
        margin: 0 25px 0 0;
        &:nth-last-child(1){
          margin: 0;
        }
        .link{
          padding: 100% 5px 5px 5px;
          font-weight: $thin-bold;
          font-size: 24px;
          transition: 0.4s;
          position: relative;
          overflow: hidden;
          &:after{
            position: absolute;
            content: '';
            bottom: -8px;
            left: 0;
            width: 0;
            background-color: $dark-pink;
            height: 4px;
          }
          &:hover{
            &:after {
              width: 100%;
            }
              background-color: $dark-pink;
          }

        }
        .activeLink{
          background-color: $dark-pink;
          &:after{
            width: 100%;
          }
        }
      }
    }
    .hamburgerMenu{
      position: fixed;
      display: none;
      width: 30px;
      height: 30px;
      overflow: hidden;
      right: 10px;
      cursor: pointer;
      &:hover{
        span{
          &:nth-of-type(2){
            left: 5px;
          }
        }
      }
      span{
        transition: 0.4s;
        left: 0;
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: $dark-pink;
        &:nth-child(1){
          top: 0;
        }
        &:nth-child(2){
          top: 12.5px;
        }
        &:nth-child(3){
          top: 25px;
        }
      }

    }
    .activeMenu{
      &:hover{
        span{
          &:nth-of-type(2){
            left: 100%
          }
        }
      }
      span{
        &:nth-of-type(1){
          top: 50%;
          transform: rotate(45deg);
        }
        &:nth-of-type(2){
          left: 100%;
        }
        &:nth-of-type(3){
          top: 50%;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media(max-width: 1100px){
  .navigationWrapper{
    padding: 50px 0 50px 0;
    .navigation{
      overflow: hidden;
      .listOfLinks{
        display: flex;
        position: fixed;
        right: -100%;
        width: 80%;
        flex-direction: column;
        z-index: 500;
        background-color: #ffd3ca;
        align-items: center;
        transition: 0.4s;
        top: 0;
        justify-content: center;
        height: 100vh;
        .item{
          margin: 0 0 15px 0;
          .link{
            padding: 0;
          }
        }
      }
      .activeListOfLink{
        right: -50px;
      }
      .hamburgerMenu{
        display: block;
        z-index: 501;
      }
    }
  }
}

