.assistant{
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

