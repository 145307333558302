@import '../../../styles/_variables.scss';

.priceList{
  position: relative;
  padding: 80px 0;
  .titleWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    .priceListTitle{
      border-bottom: 5px solid #F28686;
      padding: 0 0 15px 0;
      font-size: 38px;
      font-weight: 700;
      text-align: center;
    }
    .priceListText{
      line-height: 22px;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      max-width: 800px;
    }
  }

  .tableWrapper{
    margin-bottom: 80px;
    .titleWrapper{
      display: flex;
      justify-content: center;
      .title{
        text-align: center;
        border-bottom: 5px solid #F28686;
        padding: 0 0 15px 0;
        font-size: 38px;
        font-weight: 700;
      }
      .titleTable{
        text-align: center;
        border-bottom: 5px solid #F28686;
        padding: 0 0 15px 0;
        font-size: 32px;
        font-weight: 500;
      }
    }
    .priceTable{
      margin-top: 20px;
      border-collapse: collapse;
      width: 100%;
      .tableTitle{
        padding: 12px 0;
        border: 1px solid $light-pink;
        width: 33%;
        font-size: 32px;
        font-weight: 400;
      }
      .tableDescription{
        font-size: 18px;
        text-align: center;
        border: 1px solid $light-pink;
        padding: 10px 12px;
        font-weight: 400;
      }
      .table{
        &:nth-child(even){
          background-color: $light-pink;
        }
        &:nth-last-child(1){
          border: 1px solid #FFD3CA;
        }
      }
    }
  }
  .seeMoreWrapper{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .seeMore{
      background-color: $dark-pink;
      color: #fff;
      padding: 10px 20px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}