@import '../../styles/_variables.scss';

.gridContainer{
  margin-top: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  .gridItem{
    display: flex;
    gap: 20px;
    .itemImage {
      width: 30%;
      aspect-ratio: 2 / 1;
      border-radius: 20px;
      object-fit: cover;
    }
    .itemTitle{
      color: $dark-pink;
      font-size: 28px;
      font-weight: bold;
      font-family: Cantarell, sans-serif;
    }
    .itemShortDesc{
      font-family: open-sans, sans-serif;
      line-height: 20px;
    }
    .itemShowMore{
      padding: 10px 20px;
      border-radius: 20px;
      background: $dark-pink;
      color: #fff;
      display: inline-block;
    }
  }
}