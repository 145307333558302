@import '../../styles/_variables.scss';

.container {
  max-width: 1256px;
  margin: 0 auto;
  padding: 0 20px;
}

.aboutMe{
  padding: 80px 0;
  position: relative;
  .titleWrapper{
    display: flex;
    justify-content: center;
    .aboutMeTitle{
      border-bottom: 5px solid #F28686;
      padding: 0 0 15px 0;
      font-size: 38px;
      font-weight: 700;
    }
  }
  .topInfoWrapper{
    margin: 20px 0 50px 0;
    display: flex;
    .topInfo{
      width: 70%;
      .title{
        font-size: 26px;
        font-weight: bold;
      }
      .text{
        margin: 20px 0;
        line-height: 26px;
        font-size: 20px;
        font-weight: 300;
      }
      .list{
        margin-top: 12px;
        .item{
          font-size: 16px;
        }
      }
    }

    .selfPhotoWrapper{
      margin-left: 80px;
      width: 30%;
      .selfPhoto{
        width: 100%;
      }
    }

  }
  .peopleWrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .people{
      display: flex;
      padding: 20px;
      border: 2px solid $dark-pink;
      border-radius: 16px;
      .information{
        margin-right: 25px;
        width: 50%;
        .title{
          font-size: 26px;
        }
        .paragraph{
          font-size: 18px;
          font-weight: 300;
          margin: 10px 0;
        }
        .list{
          .item{
            font-size: 18px;
            font-weight: 300;
            margin-left: 10px;
            margin-bottom: 7px;
          }
        }
      }
      .imageWrapper{
        width: 45%;
        .image{
          width: 100%;
        }
      }
    }
  }
}

@media(max-width: 1256px){
  .aboutMe{
    .peopleWrapper{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media(max-width: 700px){
  .aboutMe{
    .topInfoWrapper{
      flex-direction: column;
      .topInfo{
        order: 2;
        width: 100%;
      }
      .selfPhotoWrapper{
        order: 1;
        margin: 0 0 40px 0;
        width: 60%;
        .selfPhoto{
        }
      }

    }
    .peopleWrapper{
      .people{
        flex-direction: column;
        .information{
          width: 100%;
          margin-bottom: 20px;
        }
        .imageWrapper{
          width: 100%;
          .image{
            width: 100%;
          }
        }
      }
    }
  }
}