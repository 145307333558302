@import '../../../styles/_variables.scss';

.locationWrapper{
  background-color: #fff;
  .location{
    padding: 50px 0;
    margin: 0 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .freeTransport{
      display: flex;
      align-items: center;
      .locationIcon{
        margin: 0 20px 0 0;
        width: 100px;
      }
      .locationTitle{
        font-size: 26px;
      }
    }
  }
  .mapImage{
    width: 100%;
  }

}

