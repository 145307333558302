.back{
  position: absolute;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  transition: 0.3s;
  top: -30px;
  background: none;
  .icon{
    margin-right: 4px;
    transform: rotate(-90deg);
  }
  &:hover{
    color: #f28686;
  }
}