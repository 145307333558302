@import '../../styles/_variables.scss';

.footerWrapper{
  padding: 100px 0 0 0;
  background-color: #fff;
  .haluxInformation{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .logo{
      width: 25%;
    }
    .haluxSubtitle{
      text-align: center;
      font-size: 26px;
      font-weight: $thin-bold;
      margin: 15px 0;
    }
    .haluxText{
      font-size: 20px;
      font-weight: $thin-light;
    }
    .socialMedia{
      margin-top: 20px;
      .socialMediaTitle{
        font-size: 20px;
        font-weight: normal;
      }
      .facebookLink{
        display: inline-block;
        margin-top: 12px;
        .facebookIcon{
          font-size: 42px;
          color: #0866ff
        }
      }
    }
  }
  .footer{
    padding: 0 0 50px 0;
    border-bottom: 2px solid $dark-pink;
    max-width: $max-page-width;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    justify-content: space-between;

    .navigation{
      padding: 0 25px 0 0;
      border-right: 2px solid $dark-pink;
      .navigationTitle{
        font-size: 26px;
        font-weight: $thin-bold;
      }
      .listOfLink{

        .item{
          margin: 15px 0 0 0;
          .link{
            transition: 0.5s;
            font-size: 22px;
            font-weight: $thin-light;
          }
          .sublistOfLink{
            margin-top: 4px;
            list-style: circle;
            margin-left: 12px;
            .sublistOfLinkItem{
              margin-left: 16px;
              .sublistOfLinkItemLink{
                font-size: 16px;
                font-weight: $thin-light;
              }
            }
          }
        }
      }
    }
    .contact{
      .contactTitle{
        font-size: 26px;
        font-weight: $thin-bold;
      }
      .sectionWrapper{
        .contactSection{
          margin: 20px 0 0 0;
          .contactSectionTitle{
            font-size: 20px;
            margin: 0 0 6px 0;
          }
          .phoneNumber{
            font-weight: $thin-weight;
            font-size: 20px;
            transition: 0.5s;
          }
          .email{
            font-size: 20px;
            font-weight: $thin-weight;
            transition: 0.5s;
          }
        }
      }
    }
  }
  .bottomFooter{
    text-align: center;
    max-width: $max-page-width;
    margin: 0 auto;
    padding: 30px 15px;
    .bottomFooterText{
      font-size: 18px;
      .author{
        font-size: 20px;
        color: $dark-pink;
        &:hover{
          color: $light-pink;
        }
      }
    }
  }
}

@media(max-width: 1000px){
  .footerWrapper{
    .footer{
      margin: 0 75px;
      grid-template-columns: repeat(1, 1fr);
      display: flex;
      flex-direction: column;
      align-items: center;
      .haluxInformation{
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 25px 0;
        border-right: none;
        border-bottom: 2px solid $dark-pink;
        grid-column-start: 1;
        grid-column-end: 2;
        .haluxText{
          text-align: center;
        }
      }
      .navigation{
        padding: 0 0 25px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: none;
        border-bottom: 2px solid $dark-pink;
        .listOfLink{
          display: flex;
          margin: 15px 0 0 0;
          .item{
            margin: 0 0 0 20px;
            &:nth-of-type(1){
              margin: 0;
            }
          }
        }
      }
      .contact{
        padding: 0 0 25px 0;
        border-right: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        .sectionWrapper{
          display: flex;
          margin: 15px 0 0 0;
          .contactSection{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 30px 0 0;
            &:nth-of-type(2){
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 1000px){
  .footerWrapper{
    .haluxInformation{
      .logo{
        width: 60%;
      }
    }
    .footer{
      .navigation{
        .listOfLink{
          flex-direction: column;
          align-items: center;
          .item{
            text-align: center;
            margin: 0 0 15px 0;
            &:nth-of-type(1){
              margin: 0 0 15px 0;
            }
            .sublistOfLink{
              margin-top: 6px;
              margin-left: 0;
              list-style: none;
            }
          }
        }
      }
      .contact{
        .sectionWrapper{
          margin-top: 50px;
          flex-direction: column;
          .contactSection{
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }
}

@media(max-width: 500px){
  .footerWrapper{
    .footer{
      margin: 0 25px;
    }
  }
}