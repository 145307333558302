@import '../../../styles/_variables.scss';

.review{
  display: flex;
  border-radius: 12px;
  width: 100%;
  padding-top: 14px;
  align-items: center;
  flex-direction: column;
  border: 2px solid #fff;
  margin: 0 20px;
  .reviewTopPanel{
    display: flex;
    flex-direction: column;
    align-items: center;
    .reviewAvatarWrapper{
      margin-bottom: 12px;
      //background-color: #ffffff;
      border-radius: 50%;
      //padding: 20px;
      max-width: 100px;
      .reviewAvatar{
        width: 100%;
      }
    }
    .reviewTitle{
      text-align: center;
      font-size: 20px;
    }
    .starsRating{
      margin-top: 6px;
      .star{
        color: $dark-pink;
        font-size: 20px;
      }
    }
  }
  .reviewContent{
    width: 100%;
    background-color: #fff;
    margin-top: 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 14px;
    .reviewContentText{
      text-align: center;
      font-weight: 400;
      font-size: 17px;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
    .bottomContent{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .reviewTime{
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
      }
      .googleLogoLink{
        .googleLogo{
          max-width: 100px;
        }
      }
    }
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slideInFromLeft {
  animation: slideInFromLeft 0.5s forwards;
}

.slideInFromRight {
  animation: slideInFromRight 0.5s forwards;
}

.slideOutToLeft {
  animation: slideOutToLeft 0.5s forwards;
}

.slideOutToRight {
  animation: slideOutToRight 0.5s forwards;
}

@media(max-width: 600px){
  .review{
    .reviewContent{
      .bottomContent{
        flex-direction: column;
      }
    }
  }
}